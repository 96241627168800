import axios, { Axios } from "axios";
import { decodeJwt } from "jose";

import { BaseUrl } from '../helpers/path-resolver';

export type User = {
    name: string;
    email: string;
    groups: string[];
    profilePictureUrl: string;
    expiresAt: number;
}

type PostGoogleAuthCallbackResponse = {
    accessToken?: string;
    error?: string;
}

type DeregisterFn = () => void;
type UserChangeListener = (user?: User) => void;

export class AuthService {
    public static readonly Shared: AuthService = new AuthService();
    private listeners: UserChangeListener[] = [];
    private readonly client: Axios;

    private _user?: User;

    public get user() {
        return this._user;
    }

    constructor() {
        this.client = axios.create({
            baseURL: BaseUrl
        });

        this.tryLoadingToken();
    }

    public logout(): void {
        localStorage.removeItem('token');

        if (this._user) {
            this._user = undefined;
            this.notify();
        }
    }

    public async exchangeGoogleOAuthCode(code: string): Promise<void> {
        const { data: { accessToken, error } } =
            await this.client.post<PostGoogleAuthCallbackResponse>(
                '/api/auth/google/callback',
                { code },
                { validateStatus: () => true }
            );

        if (accessToken) {
            localStorage.setItem('token', accessToken);
            this.tryLoadingToken();
        } else {
            throw new Error(error);
        }
    }

    public onAuthStateChanged(fn: UserChangeListener): DeregisterFn {
        this.listeners.push(fn);

        return () => {
            const idx = this.listeners.indexOf(fn);
            this.listeners = this.listeners.splice(idx, 1);
        };
    }

    private notify() {
        this.listeners.forEach(f => f(this._user));
    }

    private tryLoadingToken() {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = decodeJwt(token);
            this._user = {
                name: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string,
                email: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"] as string,
                profilePictureUrl: decodedToken['pic'] as string,
                groups: decodedToken["groups"] as string[],
                expiresAt: decodedToken["exp"] as number,
            }

            const currentTime = Date.now() / 1000;
            const timeToExpiration = this._user.expiresAt - currentTime;

            if (timeToExpiration < 0) {
                this.logout();
            } else {
                setTimeout(() => {
                    this.logout();
                }, timeToExpiration * 1000);

                this.notify();
            }
        }
    }
}
