import { Button } from 'antd';
import {GoogleOutlined} from '@ant-design/icons';
import { useGoogleLogin } from "@react-oauth/google";
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import useGoogleConfig from '../hooks/config-hook';

const LoginPage = () => {
  const { redirectUri, isLoading } = useGoogleConfig();
  const { isAuthorized } = useAuth();

  const handleLogin = useGoogleLogin({
    flow: "auth-code",
    redirect_uri: redirectUri,
    ux_mode: "redirect",
    scope: 'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email'
  });

  if (isAuthorized) {
    return <Navigate to="/" replace />;
  }

  return <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    }}
  >
    <h1>Login to FlightDeck</h1>
    <Button icon={<GoogleOutlined />} loading={isLoading} onClick={() => handleLogin()}>Log in with Google</Button>
  </div >
};

export default LoginPage;
