import { useEffect, useState } from 'react';
import { Spin, notification } from 'antd';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';

export const GoogleAuthCallbackPage: React.FC = () => {
  const { exchangeGoogleOAuthCode } = useAuth();
  const nav = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');

  useEffect(() => {
    if (code) {
      exchangeGoogleOAuthCode(code)
        .then(_ => {
          setLoading(false);
        })
        .catch(err => {
          notification.error({
            message: 'Error during login.',
            description: err.message,
            closeIcon: null
          });
          nav('/login');
        })
    }
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return <Navigate to="/" replace />;
};
