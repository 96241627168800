import { RouterProvider } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConfigProvider, Spin } from 'antd';
import useGoogleConfig from './hooks/config-hook';
import router from "./configuration/router";

import { ProvideAuth } from './hooks/use-auth';

export const AppRoot = () => {
  const { clientId, isLoading } = useGoogleConfig();

  if (isLoading || !clientId) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: '#f04e32'
      }
    }}>
      <ProvideAuth>
        <GoogleOAuthProvider clientId={clientId}>
          <RouterProvider router={router} />
        </GoogleOAuthProvider>
      </ProvideAuth >
    </ConfigProvider>
  );
}
