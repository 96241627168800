import { useEffect, useState } from 'react';
import axios from 'axios';
import { resolveBackendPath } from '../helpers/path-resolver';

type Config = {
  clientId: string | undefined;
  redirectUri: string;
};

type UseGoogleConfigHook = {
  clientId?: string;
  redirectUri?: string;
  isLoading: boolean;
}

const loadConfigFromLocalStorage = (): Config | null => {
  const clientId = localStorage.getItem('googleClientId');
  const redirectUri = localStorage.getItem('googleRedirectUri');

  if (clientId && redirectUri) {
    return { clientId, redirectUri }
  }

  return null;
}

const useGoogleConfig: () => UseGoogleConfigHook = () => {
  const [config, setConfig] = useState<Config | null>(loadConfigFromLocalStorage());
  const [isLoading, setIsLoading] = useState<boolean>(config == null);

  useEffect(() => {
    if (!config) {
      axios.get(resolveBackendPath('/api/auth/google/configuration')).then(response => {
        const { clientId, redirectUri } = response.data;
        localStorage.setItem('googleClientId', clientId);
        localStorage.setItem('googleRedirectUri', redirectUri);

        setConfig({ clientId, redirectUri });
        setIsLoading(false);
      });
    }
  }, []);

  return { ...config, isLoading };
};

export default useGoogleConfig;
