enum Environment {
    dev = 'https://flightdeck.mantaro.dev',
    staging = 'https://flightdeck.mantaro-test.tech',
    production = 'https://flightdeck.mantaro.tech',
}

enum HostedAPI {
    dev = 'https://flightdeck.mantaro.dev',
    staging = 'https://api.flightdeck.mantaro-test.tech',
    production = 'https://api.flightdeck.mantaro.tech',
}

export const resolveBackendPath = (path: string): string => {
    const domain = window.location.origin;
    switch (domain) {
        case Environment.staging:
            return HostedAPI.staging + path;
        case Environment.production:
            return HostedAPI.production + path;
        default:
            return HostedAPI.dev + path;
    }
}

export const BaseUrl = resolveBackendPath('');
