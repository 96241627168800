import { Layout } from "antd";
import SideNav from "./sidenav";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/use-auth";

const AppSkeleton = () => {
  const { isAuthorized } = useAuth();

  if (!isAuthorized) {
    return <Navigate to="/login" />
  }

  return (
    <Layout style={{ height: "100vh" }}>
      <SideNav />
      <Layout.Content>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};

export default AppSkeleton;
