import { PropsWithChildren, useState, useEffect, useContext, createContext } from "react";
import { AuthService, User } from "../services/auth-service";

type UseAuthSignature = {
    user?: User;

    isAuthorized: boolean;

    logout(): void;
    exchangeGoogleOAuthCode(code: string): Promise<void>;
}

const authInstance = AuthService.Shared;
const authContext = createContext<AuthService>(authInstance);

type UseAuthSignatureFn = () => UseAuthSignature;

export const useAuth: UseAuthSignatureFn = () => {
    const auth = useContext(authContext);
    const [user, setUser] = useState<User | undefined>(auth.user);

    const exchangeGoogleOAuthCode = (code: string) => {
        return auth.exchangeGoogleOAuthCode(code);
    };

    const logout = () => {
        auth.logout();
    };

    useEffect(() => {
        const unsubscribe = auth
            .onAuthStateChanged((user) => {
                if (user) {
                    setUser(user);
                } else {
                    setUser(undefined);
                }
            });

        return () => unsubscribe();
    }, []);

    return {
        user,
        isAuthorized: user !== undefined,
        logout,
        exchangeGoogleOAuthCode,
    };
}

export const ProvideAuth = ({ children }: PropsWithChildren) => {
    const Provider = authContext.Provider;
    return <Provider value={authInstance}>{children}</Provider>
}
