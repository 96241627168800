import { resolveBackendPath } from '../helpers/path-resolver';

const getReportConfig = async () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', 'Bearer ' + token);

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    };

    const reportConfigResponse = await fetch(resolveBackendPath('/api/reports/p-and-l-dashboard'), requestOptions);
    const reportConfig = await reportConfigResponse.json();

    return reportConfig;
};

export default getReportConfig;
