import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from 'antd';
import { models, Report, Embed } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import 'powerbi-report-authoring';
import getReportConfig from '../../hooks/powerbi-embed-hook';
import './plReport.css';

function PLReport(): JSX.Element {
    const [report, setReport] = useState<Report>();
    const [isEmbedded, setIsEmbedded] = useState<boolean>(false);

    const [templateReportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        settings: undefined,
    });

    const embedReport = useCallback(async (): Promise<void> => {
        const reportConfig = await getReportConfig();

        setReportConfig({
            ...templateReportConfig,
            embedUrl: reportConfig.embedParams.embedReport[0].embedUrl,
            accessToken: reportConfig.embedParams.embedToken.token,
        });
        setIsEmbedded(true);
    });

    useEffect(() => {
        if (report) {
            report.setComponentTitle('Embedded Report');
        } else {
            embedReport();
        }
    }, [report, embedReport]);

    const reportComponent = (
        <PowerBIEmbed
            cssClassName='report-container'
            embedConfig={templateReportConfig}
            getEmbeddedComponent={(embedObject: Embed) => {
                setReport(embedObject as Report);
            }}
        />
    );

    return (
        <div className='container'>
            <Typography.Text className='header' level={2}>
                Power BI P&L Report
            </Typography.Text>
            <div className='controls'>{isEmbedded ? reportComponent : null}</div>
        </div>
    );
}

export default PLReport;
