import { Typography } from 'antd';
import React from 'react';
import PLReport from './powerbi/plReport';

const Reports: React.FC = () => {
    return (
        <>
            <Typography.Title level={2}>Reports</Typography.Title>
            <PLReport></PLReport>
        </>
    );
};

export default Reports;
