import { Anchor, Typography } from "antd";
import React from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

const { Link } = Anchor;
interface DataType {
  key: string;
  name: string;
  brandManager: string;
  brandStatus: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Brand Code",
    dataIndex: "key",
    rowScope: "row",
    render: (text) =><Anchor>
      <Link href="/app/brands/1" title={text} />
    </Anchor>,
  },
  {
    title: "Brand Name",
    dataIndex: "name",
  },
  {
    title: "Brand Manger",
    dataIndex: "brandManager",
  },
  {
    title: "Brand Status",
    dataIndex: "brandStatus",
  },
];

const data: DataType[] = [
  {
    key: "NOS",
    name: "Nation Of Strong",
    brandManager: "Jan",
    brandStatus: "good",
  },
  {
    key: "KFS",
    name: "Kleiner Fuchs",
    brandManager: "Abdelrahman",
    brandStatus: "good",
  },
  {
    key: "AMA",
    name: "Amatopia",
    brandManager: "Nderim",
    brandStatus: "good",
  },
  {
    key: "HEL",
    name: "Amatopia",
    brandManager: "Tunar",
    brandStatus: "good",
  },
];
const Brands: React.FC = () => {
  return (
    <>
      <Typography.Title style={{ marginLeft: "16px" }} level={2}>
        Brands
      </Typography.Title>
      <Table columns={columns} pagination={false} size="small" dataSource={data} bordered />
    </>
  );
};

export default Brands;
