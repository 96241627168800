import { Typography } from "antd";
import React from "react";
import { Tabs } from "antd";

const onChange = (key: string) => {
  console.log(key);
};

const arr = ["Overview","Configuration","Products","etc"]

const Brand: React.FC = () => {
  return (
    <div>
      <Typography.Title  level={2}>
        Brands
      </Typography.Title>
      <Typography.Title  level={4}>
        Nation Of Strong
      </Typography.Title>
      <Tabs
        onChange={onChange}
        type="card"
        items={arr.map((e, i) => {
          const id = String(i + 1);
          return {
            label: e,
            key: id,
            children: (
              <Typography.Title style={{ marginLeft: "16px" }} level={4}>
               Content of  {e}
              </Typography.Title>
            ),
          };
        })}
      />
    </div>
  );
};

export default Brand;
